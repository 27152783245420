export function useTimeoutTimer() {
  const timer = ref(0)
  const isIntervalCreated = ref(false)
  const isFinished = ref(false)

  const timerFormatted = computed(() => {
    const hours = Math.floor(timer.value / 3600)
    const minutesDigits = Math.floor((timer.value % 3600) / 60)
    const minutes = minutesDigits > 9 ? minutesDigits : `0${minutesDigits}`
    const secondsDigits = timer.value % 60
    const seconds = secondsDigits > 9 ? secondsDigits : `0${secondsDigits}`

    return timer.value >= 3600
      ? `${hours}:${minutes}:${seconds}`
      : `${minutes}:${seconds}`
  })

  const interval = ref()

  function startTimer(timerValue: number) {
    timer.value = timerValue
    isFinished.value = false

    if (isIntervalCreated.value) {
      return
    }

    interval.value = setInterval(() => {
      isIntervalCreated.value = true
      timer.value -= 1

      if (timer.value <= 0) {
        clearInterval(interval.value)
        isIntervalCreated.value = false
        isFinished.value = true
      }
    }, 1000)
  }

  onBeforeUnmount(() => {
    clearInterval(interval.value)
  })

  return {
    startTimer,
    isFinished,
    timer: timerFormatted,
    rawTimer: timer,
  }
}
