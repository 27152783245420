<template>
  <div class="two-factor" :class="{ platform }" data-t="st-2fa">
    <StCodeInput
      v-model="code"
      :success="props.isCodeValid"
      :error="!!errorMessage"
      :error-message="errorMessage"
      :length="CODE_LENGTH"
      :size="codeInputSize"
      data-t="code-input"
      autofocus
      @code-entered="emit('inputFilled', code)"
    />
    <div v-if="rawTimer <= 0" class="resend-code">
      <div v-if="rawCountDown" class="countdown" data-t="countdown">
        {{ t('authorization.resendCode', { value: countdown }) }}
      </div>
      <div v-else class="send-code">
        <span class="text">{{ t('authorization.noCode') }}</span>
        <StButton
          class="button"
          :size="platform === 'mobile' ? 'm' : 's'"
          :label="t('authorization.sendCodeAgain')"
          @click="resendCode"
        />
      </div>
    </div>
    <div v-if="isNoEmailAccessVisible" class="no-email-access">
      <StButton
        type="text-only"
        :size="platform === 'mobile' ? 'm' : 's'"
        :label="t('authorization.restoreEmalAccess')"
        :to="supportEmailLink"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Error2FA, Error2FACodes } from './interfaces'
import { useSettingsStore } from '../../stores/useSettingsStore'

type ErrorCodeMap = Record<Error2FACodes, string> | undefined

const CODE_LENGTH = 6

const { t } = useI18n()
const { startTimer, timer, rawTimer } = useTimeoutTimer()
const {
  startTimer: startCountDown,
  timer: countdown,
  rawTimer: rawCountDown,
} = useTimeoutTimer()

const { supportEmailLink } = useSupportUrl()

const props = withDefaults(
  defineProps<{
    isCodeValid: boolean
    isResendCodeVisible?: boolean
    error?: unknown
    isWide?: boolean
    platform?: 'desktop' | 'mobile'
    isNoEmailAccessVisible?: boolean
  }>(),
  {
    isResendCodeVisible: true,
    isWide: false,
    platform: 'desktop',
    isNoEmailAccessVisible: true,
  },
)

const { settings } = storeToRefs(useSettingsStore())
const confirmationTimeoutInSec = computed(
  () => settings.value?.confirmationTimeoutInSec ?? 180,
)

const code = ref('')

const codeInputSize = computed(() => {
  if (props.platform === 'mobile') {
    return 's'
  }

  if (props.platform === 'desktop' && props.isWide) {
    return 'l'
  }

  return 'm'
})

const emit = defineEmits<{
  (e: 'resendCodeClicked'): void
  (e: 'inputFilled', value: string): void
}>()

function refreshCountdown() {
  startCountDown(confirmationTimeoutInSec.value)
}

function resendCode() {
  refreshCountdown()
  emit('resendCodeClicked')
}

onMounted(() => {
  startCountDown(confirmationTimeoutInSec.value)
})

watch(
  () => props.error,
  () => {
    if (!props.error) return

    const errorBody = (props.error as Error2FA).data
    if (!errorBody) return

    const { error: errorCode, data } = errorBody

    if (errorCode === 'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED') {
      const time = data.ttl || 0
      const timeout = Math.floor(time / 1000)
      if (!timeout) return

      startTimer(timeout)
    }
  },
)

const errorCodeMap: ComputedRef<ErrorCodeMap> = computed(() => {
  if (!props.error) return undefined

  const errorBody = (props.error as Error2FA).data
  if (!errorBody) return undefined

  const { data } = errorBody

  return {
    CONFIRMATION_CODE_NOT_VALID: t(
      'authorization.errorMessages.confirmationCodeisNotValid',
      {
        value: data?.attemptsLeft || 0,
      },
    ),
    CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED: t(
      'authorization.errorMessages.confirmationCodeMaxAttemptsError',
      {
        value: timer.value,
      },
    ),
  }
})

const errorMessage = computed(() => {
  if (!props.error) return undefined

  const errorBody = (props.error as Error2FA).data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody
  if (!errorCodeMap.value) return undefined

  if (
    rawTimer.value === 0 &&
    errorCode === 'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED'
  ) {
    return undefined
  }

  return (
    errorCodeMap.value[errorCode as Error2FACodes] ||
    t('authorization.errorMessages.somethingWentWrong')
  )
})
</script>
<style scoped>
.resend-code {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-250);

  .countdown {
    font: var(--desktop-text-xs-medium);
    color: var(--text-secondary);
    text-align: center;
  }

  .send-code {
    text-align: center;

    .text {
      font: var(--desktop-text-xs-medium);
      color: var(--palette-light-1000);
      opacity: 0.48;
    }

    .button {
      cursor: pointer;
      font: var(--desktop-text-xs-semibold);
      color: var(--palette-brand-500);
      background-color: transparent;
    }
  }
}

.no-email-access {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-100);
}

.two-factor {
  &.mobile {
    .resend-code {
      margin-top: var(--spacing-300);
    }

    .send-code {
      .text {
        font: var(--desktop-text-xs-medium);
      }
    }

    .countdown {
      font: var(--mobile-text-content-regular);
      color: var(--text-tertiary);
    }
  }
}
</style>
